import { Box, Text } from "@chakra-ui/react";
import TopicCarousel from "components/TopicCarousel";

export default ({ topics = [] }) => {
  return (
    topics && (
      <Box marginTop={{ base: "25px", md: "100px" }}>
        {topics?.length > 0 ? (
          topics?.map((topic) => <TopicCarousel topic={topic} />)
        ) : (
          <Text>Não há nenhum conteúdo sobre esse assunto ainda</Text>
        )}
      </Box>
    )
  );
};
