import Loading from "components/Loading";
import { useAssociateExists } from "hooks/useAuth";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "services/appaiAuth";

const AssociateExists = ({setIsLoading}) => {
  const [associateExists] = useAssociateExists();
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthUser = async () => {
      const data = await getUser();
      if (data) {
        const associate = data.profile;

        const exists = await associateExists({
          variables: {
            input: {
              name: associate.nome,
              phone: associate.tel,
              email: associate.email,
              registration: associate.matricula || associate.mat,
            },
          },
        });


        if(!exists.data.associateExists.adesao) window.location.href= process.env.REACT_APP_APPAI_AUTH_REDIRECT;
        else setIsLoading(false);
      } else {
        navigate("/login");
      }
    };

    getAuthUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default AssociateExists;
