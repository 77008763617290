import { createContext, useContext } from "react";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { ME, HAS_ANSWER, ACCEPT_TERMS, SKIP_ASSESSMENT } from "graphql/auth";
import { authCreators } from "state";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { ASSOCIATE_EXISTS } from "graphql/auth";
import { REQUEST_FORM } from "graphql/auth";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const AuthProvider = ({ children }) => {
  return (
    <AuthStateContext.Provider>
      <AuthDispatchContext.Provider>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthStateContext);

  if (!context || context === undefined)
    throw new Error("useAuth must be used within an AuthProvider");

  return context;
};

const useAuthUser = (id) => {
  const dispatch = useDispatch();
  const { me } = bindActionCreators(authCreators, dispatch);

  return useLazyQuery(ME, {
    variables: {
      id,
    },
    onCompleted: (data) => me(data.geMe),
  });
};

const useAssociateExists = () => {
  const dispatch = useDispatch();
  const { me } = bindActionCreators(authCreators, dispatch);

  return useMutation(ASSOCIATE_EXISTS, {
    onCompleted: (data) => me(data.associateExists)
  });
};

const useHasAnswer = () => {
  const dispatch = useDispatch();
  const { hasAnswer } = bindActionCreators(authCreators, dispatch);

  return useLazyQuery(HAS_ANSWER, {
    onCompleted: (data) => hasAnswer(data.associateAnswer),
  });
};

const useRequestForm = () => {
  return useLazyQuery(REQUEST_FORM);
};

const useAcceptTerms = () => {
  const dispatch = useDispatch();
  const { acceptTerms } = bindActionCreators(authCreators, dispatch);

  return useMutation(ACCEPT_TERMS, {
    onCompleted: (data) => acceptTerms(data.acceptTerms),
  });
};

const useSkipAssessment = () => {
  const dispatch = useDispatch();
  const { hasAnswer } = bindActionCreators(authCreators, dispatch);

  return useMutation(SKIP_ASSESSMENT, {
    onCompleted: () => hasAnswer({hasAnswer: true})
  });
};

export {
  AuthProvider,
  useAuth,
  useAuthUser,
  useAssociateExists,
  useHasAnswer,
  useRequestForm,
  useAcceptTerms,
  useSkipAssessment,
};
