import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query listCategories($where: QueryListCategoriesWhereWhereConditions) {
    listCategories(where: $where) {
      id
      title
      description
      url_safe_title
      icon
      type
      button_label
    }
  }
`;

export const GET_CONTENT = gql`
  query searchContent($associate_id: String, $search_key: String) {
    searchContent(associate_id: $associate_id, search_key: $search_key)
  }
`;

export const GET_POST = gql`
  query getPost($id: String) {
    searchContent(search_key: $id)
  }
`;
