import {
  Flex,
  Box,
  Text,
  Image,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";

import FullCircleSVG from "components/Graphics/FullCircleSVG";
import SketchLineSVG from "components/Graphics/SketchLineSVG";

export default () => {
  const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 768px)", {
    ssr: false,
  });

  const mask = {
    mask: 'url(\'data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8"%3F%3E%3Csvg preserveAspectRatio="none" data-bbox="47.1 20 105.9 160" viewBox="47.1 20 105.9 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="shape"%3E%3Cg%3E%3Cpath d="M100 20c-29.2 0-52.9 23.7-52.9 52.9V180H153V72.9c-.1-29.2-23.8-52.9-53-52.9z"/%3E%3C/g%3E%3C/svg%3E%0A\')',
    maskPosition: "0% 0%",
    maskSize: "100% 100%",
    maskRepeat: "no-repeat",
    borderRadius: "150px 150px 0 0",
  };

  return (
    <Flex w="100%" justify="space-between" paddingY="5em" bg="white">
      <Flex direction="column">
        <Box ml={{ base: "20px", md: "150px" }} mb="50px">
          <Text
            fontWeight="thin"
            fontSize="sm"
            color="gray.500"
            letterSpacing="2px"
          >
            Sobre o Programa Saúde 10
          </Text>
          <Text fontWeight="bold" fontSize={{ base: "xl", md: "2xl" }}>
            Tudo o que você precisa saber para cuidar da saúde
          </Text>
        </Box>
        <Flex direction={{ base: "column", md: "row" }}>
          <Flex
            direction="column"
            w={{ base: "100%", md: "40%", lg: "50%" }}
            mr="50px"
          >
            <Box paddingY={{ base: "none", md: "2em" }} bg="appai.light">
              <Image
                ml={{ base: "none", md: "33%", lg: "55%" }}
                objectFit="cover"
                h="440px"
                w={{ base: "100%", md: "310px" }}
                maxH={{ base: "220px", md: "none" }}
                src="/images/about.png"
                style={isLargerThanBreakpoint ? mask : null}
              ></Image>
            </Box>
          </Flex>
          <Flex
            direction="column"
            w={{ base: "100%", md: "60%", lg: "50%" }}
            padding="1.5em"
            justify="center"
          >
            <Flex position="relative" direction="column" align="center">
              <Text maxW={{ base: "none", md: "60%", lg: "50%" }} mb="25px">
              A Appai, no intuito de contribuir para a melhoria da qualidade de vida dos seus associados, 
              implantou o “Programa Saúde 10” com foco na promoção da saúde e prevenção de doenças, 
              e que tem como objetivo permitir ao associado viver seus momentos mais importantes com uma melhor qualidade de vida. 
              E não paramos por aí.
              </Text>
              <Text maxW={{ base: "none", md: "60%", lg: "50%" }} mb="50px">
              Através de parceria com empresa especializada, a APPAI trouxe novidades para o Programa Saúde 10. 
              Trata-se de uma plataforma de cuidado preventivo formada por um time de saúde multidisciplinar. 
              Nutrólogas, nutricionistas, enfermeiras, psicólogas, educadores físicos e muito mais.
              </Text>
              <Box
                position="absolute"
                bottom={-100}
                right={50}
                zIndex={1}
                transform={{ base: "rotate(60deg)", md: "none" }}
              >
                <SketchLineSVG />
              </Box>
              <Box position="absolute" bottom={{ base: -50, md: 0 }} right={65}>
                <FullCircleSVG />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
