import { Box, Center, Flex, Grid, Text } from "@chakra-ui/react";
import CategoryFrame from "components/CategoryFrame";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import Topics from "components/Topics";
import { useListTopics } from "hooks/useContent";
import { useParams } from "react-router-dom";

const Category = () => {
  const { cat } = useParams();
  const { data } = useListTopics(cat);
  const { topics } = data?.searchContent ? JSON.parse(JSON.parse(data.searchContent)) : []

  return (
    <>
      <Navbar />
      <Flex id="categories" direction="column" backgroundColor="white">
        <Box
          ml={{ base: "20px", md: "150px" }}
          mt={{ base: "210px", sm: "210px", md: "200px" }}
          backgroundColor="white"
        >
          <Text
            fontWeight="thin"
            fontSize="sm"
            color="gray.500"
            letterSpacing="2px"
          >
            Plataforma Saúde 10 Digital APPAI
          </Text>
          <Text fontWeight="bold" fontSize={{ base: "xl", md: "2xl" }}>
            Conteúdos e dicas para você utilizar no seu dia a dia
          </Text>
        </Box>
        {topics && (
          <>
            <Topics topics={topics} />
            <Footer />
          </>
        )}
      </Flex>
    </>
  );
};

export default Category;
