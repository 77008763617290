import { Button, Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'

import icon1 from 'assets/images/icon_1.png'
import icon2 from 'assets/images/icon_2.png'
import icon3 from 'assets/images/icon_3.png'
import icon4 from 'assets/images/icon_4.png'

import { Link } from 'react-router-dom'
import PrimaryButton from 'components/PrimaryButton'

const getIcon = {
    1: icon1,
    2: icon2,
    3: icon3,
    4: icon4
}

const Icon = ({ title = "", icon = 1, label = "" }) => {
    
    return (
        <VStack justifyContent="center" alignItems="center" spacing="8" mb="60px">
            <Text fontWeight="bold" fontSize="md" >{title}</Text>
            <Image src={getIcon[icon]} w="180px" h="auto" />
            <Link style={{
                    display: 'flex',
                    justifyContent: 'center'
                }} 
                to="https://www.appai.org.br/programas-projetos/programa-saude-10/" target="_blank"
            >
                <PrimaryButton >
                    {label}
                </PrimaryButton>
            </Link>
        </VStack>
    )
}

export default Icon