import { ContentActionType } from "../action-types/contentTypes";

export const getContent = (content) => {
  return (dispatch) => {
    dispatch({
      type: ContentActionType.GET,
      payload: content,
    });
  };
};

export const getParentCategories = (categories) => {
  return (dispatch) => {
    dispatch({
      type: ContentActionType.GET_PARENT_CATEGORIES,
      payload: categories,
    });
  };
};
