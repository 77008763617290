import {
  Flex,
  Box,
  Text,
  Image,
  Button,
  Link,
  useMediaQuery,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import FullCircleSVG from "components/Graphics/FullCircleSVG";
import CircleOutlineSVG from "components/Graphics/CircleOutlineSVG";
import HalfCircleOutlineSVG from "components/Graphics/HalfCircleOutlineSVG";

import PrimaryButton from "components/PrimaryButton";

import { scrollToAnchor } from "functions/scroll";

export default () => {
  const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 768px)", {
    ssr: false,
  });

  return (
    <Grid
      w="100%"
      templateColumns={{md:'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}}
      justify="space-between"
      direction={{ base: "column", md: "row" }}
      mt={{ base: "150px", md: "0" }}
    >
      <GridItem minW="50%">
        <Image
          objectFit="cover"
          maxH={{ base: "250px", md: "none" }}
          h={{ base: "250px", md: "840px" }}
          boxSize="100%"
          alignSelf="flex-start"
          src="/images/home_header.png"
        ></Image>
      </GridItem>
      <Flex w="100%" direction="column">
        <Flex
          position="relative"
          h="50%"
          minH="250px"
          bg="white"
          justify="center"
          align="center"
          paddingY="1.5em"
          paddingX={{ base: "1.5em", md: "1em" }}
        >
          <Text
            fontSize={{ sm: "lg", md: "xl", lg: "xl", xl: '2xl' }}
            mr={{ lg: "5%", xl: "25%" }}
            maxW={{ md: "300px", sm: "240px" }}
          >
            Sejam bem-vindos (as) à nova plataforma digital do Programa Saúde 10 da APPAI
          </Text>
          <Box position="relative" align="flex-end" minH="220px" minW="100px">
            <Box position="absolute" bottom={5}>
              <FullCircleSVG />
            </Box>
            <Box position="absolute" zIndex={1} bottom={5} right={35}>
              <CircleOutlineSVG />
            </Box>
            {isLargerThanBreakpoint && (
              <Box
                position="absolute"
                bottom={-105}
                rigth={300}
                transform="rotate(90deg)"
              >
                <HalfCircleOutlineSVG />
              </Box>
            )}
          </Box>
        </Flex>
        <Flex
          h="50%"
          minH="250px"
          bg="appai.light"
          textAlign="center"
          justify="center"
          align="center"
          direction="column"
          gap="32px"
          paddingY="1.5em"
        >
          <Text
            fontSize="lg"
            fontWeight="thin"
            maxW={{ base: "75%", md: "50%" }}
          >
            Agora o programa Saúde 10 da APPAI conta com uma plataforma completa com vídeos, podcasts, dietas, treinos, receitas, fórmulas de suplementação e muito mais.
          </Text>
          <PrimaryButton onClick={() => scrollToAnchor("categories")}>
            Quero cuidar da minha saúde!
          </PrimaryButton>
        </Flex>
      </Flex>
    </Grid>
  );
};
