import React, { useEffect, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";

import AuthGuard from "components/AuthGuard";
import Categories from "components/Categories";
import Footer from "components/Footer";
import Loading from "components/Loading";
import Navbar from "components/Navbar";
import Topics from "components/Topics";

import { useListParentCategories } from "hooks/useContent";

import { useSelector } from "react-redux";
import Post from "views/Post";
import BreadCrumbs from "components/Navbar/components/BreadCrumbs";
import Home from "views/Home";

const Root = () => {
  const [listParentCategories, { loading }] = useListParentCategories();
  const [renderComponent, setRenderComponent] = useState("");

  const {
    content: { parentCategories = [], current = [] },
  } = useSelector((content) => content);

  const type = Object.keys(current)[0];

  useEffect(() => {
    if (parentCategories.length <= 0) listParentCategories();
  }, []);

  useEffect(() => {
    if (type === "categories") {
      if (current?.categories === parentCategories)
        setRenderComponent(
          React.createElement(Home, { categories: current?.categories })
        );
      else
        setRenderComponent(
          React.createElement(Categories, {
            categories: current?.categories,
            topics: current?.topics,
          })
        );
    }
    if (type === "post")
      setRenderComponent(React.createElement(Post, { post: current?.post }));
  }, [current]);

  return loading ? (
    <Loading />
  ) : (
    <AuthGuard>
      <Flex direction="column" justify="space-between" w="full" h="100vh">
        <Navbar categories={parentCategories} />
        <Box mt={{ base: "0", md: "100px" }} h="100%">
          {renderComponent}
        </Box>
      </Flex>
    </AuthGuard>
  );
};

export default Root;
