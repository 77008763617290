import { ModalActionTypes } from "state/action-types/modalTypes";

const initialState = {
  open: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalActionTypes.OPEN_CLOSE:
      return { open: action.payload }
    default:
      return state;
  }
};

export default reducer;