import { extendTheme } from "@chakra-ui/react";
import { globalStyles } from "./styles";
import { font } from "./foundations/fonts";

export default extendTheme(
  {
    colors: {
      appai: {
        500: "",
      },
    },
  },
  globalStyles,
  font,
);
