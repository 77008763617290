import { Center, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();

  setTimeout(() => navigate("/"), 5000);

  return (
    <Center h="100vh">
      <Flex direction="column" align="center">
        <Heading mb=".5em" as="h1" fontSize="64px" letterSpacing="12px">
          404
        </Heading>
        <Text mb=".25em" fontSize="24px" letterSpacing="1.5px">
          A página que você está procurando não foi encontrada
        </Text>
        <Text mb=".25em" fontSize="24px">
          Auto-redirecionando para o app...
        </Text>
        <Link
          href="/"
          color="gray.600"
          transition=".25s ease"
          _hover={{ color: "gray.700" }}
        >
          Clique aqui para ir imediatamente
        </Link>
      </Flex>
    </Center>
  );
};
