import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    appai: {
      main: "#9368B6",
      darker: "#623D80",
      light: "#F1DEFF",
      buttonGrey: "#E1CDF6",
      lightGrey: "#EAECEB",
      secondaryText: "#3C3C3C",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.100", "gray.100")(props),
        fontFamily: "Roboto Condensed, Helvetica, sans-serif",
        color: "#171923",
      },
      html: {
        fontFamily: "Roboto Condensed, Helvetica, sans-serif",
        overflowY: "overlay",
      },
    }),
  },
};
