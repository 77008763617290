import { Box } from "@chakra-ui/react";

export default () => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="32.8 19.8 134.263 160.2"
      viewBox="32.8 19.8 134.263 160.2"
      height="200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="presentation"
      aria-hidden="true"
      aria-label=""
    >
      <g>
        <path
          d="M33.4 180l-.6-.1c2.4-19.5 43.6-36.1 73.7-48.2 9.9-4 18.4-7.4 23.5-10.2 7.3-3.9 11.2-7.9 11.9-12 1-6.1-4.9-12.6-11.8-20l-.5-.5c-3.4-3.7-4.9-7.6-4.4-11.8.9-8.2 9.1-15.1 14-18.4.9-.6 2.1-1.4 3.4-2.3 8.3-5.5 25.7-16.9 23.8-24.7-2.2-8.9-19.1-11.5-19.3-11.5l.1-.5c.7.1 17.4 2.6 19.7 11.9 2 8.2-14.9 19.3-24 25.3-1.4.9-2.5 1.7-3.4 2.3-4.8 3.3-12.9 10.1-13.8 18-.4 4 1 7.8 4.3 11.3l.5.5c7 7.6 13 14.1 11.9 20.5-.7 4.3-4.7 8.4-12.2 12.4-5.2 2.8-13.7 6.2-23.6 10.2-29.8 12.1-70.9 28.6-73.2 47.8z"
          fill="#010203"
          data-color="1"
        ></path>
      </g>
    </svg>
  );
};
