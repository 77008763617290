import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default ({ children, url, last, style = null }) => {
	const navigate = useNavigate();

	return (
		<Button
			display="flex"
			position="relative"
			color="white"
			minH={{ base: "30px", lg: "50px" }} F
			minW={style ? style.minW : { base: "75px", lg: "200px" }}
			maxW={{ base: "200px", lg: "300px" }}
			fontSize={{ base: "14px", lg: "18px" }}
			bg="appai.main"
			padding={style ? style.padding : { base: "1em 2em", xl: "1.5em 3em" }}
			borderRadius={0}
			_hover={{ bg: "appai.darker" }}
			onClick={() => navigate(`/${url}`)}
		>
			{children}
			{!last && (
				<Box
					position="absolute"
					right="0"
					bg="white"
					minW="2px"
					minH="50%"
					borderRadius="50px"
				/>
			)}
		</Button>
	);
};
