import { AuthActionType } from "../action-types/authTypes";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionType.ME:
      return {
        ...state,
        ...action.payload
      };
    case AuthActionType.HAS_ANSWER:
      console.log('REDUCER: ', action.payload)
      return {
        ...state,
        has_answer: action.payload.hasAnswer,
      };
    case AuthActionType.ACCEPT:
      return {
        ...state,
        accepted_at: action.payload.accepted_at,
      };
    default:
      return state;
  }
};

export default reducer;
