import { CarouselActionType } from "state/action-types/carouselTypes";

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CarouselActionType.LIST_CAROUSEL:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
