import { createContext, useContext } from "react";

import { useQuery } from "@apollo/client";

import { GET_CAROUSEL } from "graphql/carousel";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { carouselCreators } from "state";

const useCarousel = () => {
  const dispatch = useDispatch();
  const { listCarousel } = bindActionCreators(carouselCreators, dispatch);

  return useQuery(GET_CAROUSEL, {
    onCompleted: ({ listCarousel: carousel }) => {
      console.log("on completed", carousel)
      listCarousel(carousel);
    },
  });
}

export {
  useCarousel
};
