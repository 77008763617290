import { Box, Flex } from "@chakra-ui/react";
import Loading from "components/Loading";
import Navbar from "components/Navbar";
import { useGetDraft } from "hooks/useContent";
import React from "react";
import { useParams } from "react-router-dom";
import Post from "views/Post";

const Draft = () => {
  const { id } = useParams();
  const { data, loading } = useGetDraft(id);
  const post = data?.searchContent
    ? JSON.parse(JSON.parse(data.searchContent))
    : null;

  return (
    <Flex direction="column" justify="space-between" w="full" h="100vh">
        <Box mt={{ base: "0" }} h="100%">
            {
                loading ?
                    <Loading />
                : <Post draftPost={post.post} />
            }
        
        </Box>
    </Flex>
  )
};

export default Draft;
