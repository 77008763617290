import { Box, Center, Flex, Grid, Text } from "@chakra-ui/react";
import CategoryFrame from "components/CategoryFrame";
import Footer from "components/Footer";
import Topics from "components/Topics";

const Categories = ({ categories = false, topics = false }) => {
  return (
    <>
      <Flex id="categories" direction="column" backgroundColor="white">
        <Box
          ml={{ base: "20px", md: "150px" }}
          mt={{ base: "250px", sm: "250px", md: "100px" }}
          backgroundColor="white"
        >
          <Text
            fontWeight="thin"
            fontSize="sm"
            color="gray.500"
            letterSpacing="2px"
          >
            Plataforma Saúde 10 Digital APPAI
          </Text>
          <Text fontWeight="bold" fontSize={{ base: "xl", md: "2xl" }}>
            Conteúdos e dicas para você utilizar no seu dia a dia
          </Text>
        </Box>
        {categories?.length > 0 && (
          <Grid
            templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
            textAlign="center"
            align="center"
            justify="center"
            padding={{ base: ".5em 2.5em", md: "5em 3em" }}
            gap={{ base: "35px", md: "75px" }}
            bg="white"
          >
            {categories?.map((category) => (
              <CategoryFrame category={category} />
            ))}
          </Grid>
        )}
        {topics && (
          <>
            <Topics topics={topics} />
            <Footer />
          </>
        )}
      </Flex>
    </>
  );
};

export default Categories;
