import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { dateFormat } from "functions/dateFormat";
import { useRequestForm } from "hooks/useAuth";
import { useSkipAssessment } from "hooks/useAuth";
import { useHasAnswer } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Assessment = ({ isOpen, onOpen, onClose }) => {
  const auth = useSelector(({ auth }) => auth);
  const [hasAnswer, { data, loading }] = useHasAnswer();
  const [requestForm, { data: dataForm, loading: loadingForm }] =
    useRequestForm();
  const [submitSkip, { loading: loadingSkip }] = useSkipAssessment();
  const isLoading = loading || loadingSkip;
  const [form, setForm] = useState(null);
  const [verified, setVerified] = useState(false);

  console.log("AUTH: ", auth);
  console.log("verificou", verified);

  useEffect(() => {
    const checkAnswers = async () => {
      await hasAnswer({
        variables: {
          id: auth.id,
        },
      });
      setVerified(true);
    };
    if (auth && auth.id && !auth.has_answer) {
      checkAnswers();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", function (message) {
      if (message.origin === process.env.REACT_APP_FORM_BUILDER) {
        console.log("Opa", message);
        const { data: messageData } = message;
        console.log("data", messageData);
        if (messageData.success) onSkip();
      }
    });
    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  const onSkip = () => {
    const now = new Date();
    const variables = {
      id: auth.id,
      input: {
        last_notification_at: dateFormat(now, "yyyy-MM-dd HH:mm:ss"),
      },
    };
    submitSkip({ variables });
    onClose();
  };

  const onAccept = async () => {
    const { data: response } = await requestForm({
      variables: {
        id: auth.id,
        name: auth.name,
      },
    });
    console.log("response", response.requestForm);
    const urlSolicitation = response.requestForm.url + "&iframe_api=1";
    const urlFormUnparsed = await fetch(urlSolicitation, {
      method: "GET",
    });
    const urlForm = await urlFormUnparsed.json();
    console.log(`${urlForm.url}?apikey=${urlForm.set_cookie.value}`);
    setForm(`${urlForm.url}?apikey=${urlForm.set_cookie.value}`);
    //onClose();
  };

  const color = useColorModeValue("black", "white");

  return (
    <Modal
      size={form ? "xl" : "md"}
      isOpen={verified && !auth.has_answer}
      isCentered
    >
      <ModalOverlay />
      {form !== null ? (
        <ModalContent>
          <iframe
            style={{ visibility: "initial" }}
            height="500"
            width="100%"
            src={form}
            title="Formulario"
          />
          <ModalBody>
            <Box maxH="400px" mt="20px" overflowY="auto">
              <Text fontSize="lg" color={color}>
                Ao finalizar o preenchimento do formulário, clique no botão
                abaixo para fechar a janela.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={2}>
              <Button
                onClick={onSkip}
                colorScheme="purple"
                isLoading={isLoading || loadingForm}
                isDisabled={isLoading || loadingForm}
              >
                Finalizar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader>
            <Text fontSize="lg" color={color}>
              Que bom, obrigado pelo seu interesse!
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box maxH="400px" overflowY="auto">
              <Text fontSize="lg" color={color}>
                Atualize suas informações sobre bem-estar e qualidade de vida!.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={2}>
              <Button
                onClick={onSkip}
                variant="ghost"
                isLoading={isLoading || loadingForm}
                isDisabled={isLoading || loadingForm}
              >
                Agora não
              </Button>
              <Button
                onClick={onAccept}
                colorScheme="purple"
                isLoading={isLoading || loadingForm}
                isDisabled={isLoading || loadingForm}
              >
                Preencher
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
};

export default Assessment;
