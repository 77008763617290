import { ContentActionType } from "state/action-types/contentTypes";

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ContentActionType.GET:
      return { ...state, current: action.payload };
    case ContentActionType.GET_PARENT_CATEGORIES:
      return {
        parentCategories: action.payload,
        current: state?.current
          ? state.current
          : { categories: action.payload },
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
