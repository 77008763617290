export default () => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="40 40 120 120"
      viewBox="40 40 120 120"
      height="100"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="presentation"
      aria-hidden="true"
      aria-label=""
    >
      <g>
        <path
          d="M100 40v1c32.5 0 59 26.5 59 59s-26.5 59-59 59-59-26.5-59-59h-1c0 33.1 26.9 60 60 60s60-26.9 60-60-26.9-60-60-60z"
          fill="#010203"
          data-color="1"
        ></path>
      </g>
    </svg>
  );
};
