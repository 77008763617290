import { Box, Button, Flex, Text } from "@chakra-ui/react";
import FadingImage from "components/FadingImage";
import PrimaryButton from "components/PrimaryButton";
import { useNavigate } from "react-router-dom";

export default ({ category, images }) => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" gap="24px" align="center" textAlign="center">
      <Box position="relative" minW="320px" minH="400px">
        <FadingImage src={category?.icon} />
      </Box>
      <Text fontWeight="bold">{category?.title}</Text>
      <Text maxW="100%">{category?.description}</Text>
      <PrimaryButton onClick={() => navigate(category?.url_safe_title)}>
        {category?.button_label}
      </PrimaryButton>
    </Flex>
  );
};
