import { AuthActionType } from "../action-types/authTypes";

export const me = (auth) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionType.ME,
      payload: auth,
    });
  };
};

export const hasAnswer = (hasAnswer) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionType.HAS_ANSWER,
      payload: hasAnswer,
    });
  };
};

export const acceptTerms = (accepted_at) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionType.ACCEPT,
      payload: accepted_at,
    });
  };
};
