import { combineReducers } from "redux";

import authReducer from "./authReducer";
import contentReducer from "./contentReducer";
import modalReducer from "./modalReducer";
import categoryReducer from "./categoryReducer";
import carouselReducer from "./carouselReducer";

const reducers = combineReducers({
  auth: authReducer,
  content: contentReducer,
  modal: modalReducer,
  category: categoryReducer,
  carousel: carouselReducer
});

export default reducers;
