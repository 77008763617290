import { gql } from "@apollo/client";

export const ME = gql`
  query GetMe($id: ID!) {
    getMe(id: $id) {
      id
      appai_id
      name
      email
      accepted_at
    }
  }
`;

export const ASSOCIATE_EXISTS = gql`
  mutation AssociateExists($input: AssociateExists!) {
    associateExists(input: $input) {
      id
      appai_id
      name
      email
      accepted_at
      adesao
    }
  }
`;

//
export const HAS_ANSWER = gql`
  query AssociateAnswer($id: ID!) {
    associateAnswer(id: $id) {
      hasAnswer
    }
  }
`;

//
export const REQUEST_FORM = gql`
  query RequestForm($id: ID!, $name: String!) {
    requestForm(id: $id, name: $name) {
      id
      url
    }
  }
`;


export const ACCEPT_TERMS = gql`
  mutation AcceptTerms($id: ID!, $input: AssociateAcceptTerms!) {
    acceptTerms(id: $id, input: $input) {
      accepted_at
    }
  }
`;

export const SKIP_ASSESSMENT = gql`
  mutation SkipAssessment($id: ID!, $input: SkipAssessment!) {
    skipAssessment(id: $id, input: $input) {
      last_notification_at
    }
  }
`;
