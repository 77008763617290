import { useDisclosure } from "@chakra-ui/react";

import AcceptTerms from "components/AcceptTerms";
import Assessment from "components/Assessment";
import AssociateExists from "components/AssociateExists";
import Loading from "components/Loading";
import { useAssociateExists } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "services/appaiAuth";

const AuthGuard = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    isOpen: termsOpen,
    onOpen: termsOnOpen,
    onClose: termsOnClose,
  } = useDisclosure();
  const {
    isOpen: assessmentOpen,
    onOpen: assessmentOnOpen,
    onClose: assessmentsOnClose,
  } = useDisclosure();

  if (isLoading) return <AssociateExists setIsLoading={setIsLoading} />;
  else
    return (
      <>
        <AcceptTerms
          isOpen={termsOpen}
          onOpen={termsOnOpen}
          onClose={termsOnClose}
        />
        {!termsOpen && (
          <Assessment
            isOpen={assessmentOpen}
            onOpen={assessmentOnOpen}
            onClose={assessmentsOnClose}
          />
        )}
        {children}
      </>
    );
};

export default AuthGuard;
