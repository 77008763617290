import { useGetContent } from "hooks/useContent";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import { useSelector } from "react-redux";

export default () => {
  const { pathname = "/" } = useLocation();
  const navigate = useNavigate();
	const auth = useSelector(({ auth }) => auth);

  const [getContent] = useGetContent(navigate);

  React.useEffect(() => {
    const fullPath = pathname?.replace("/", "")?.split("/");
    const subject = fullPath?.pop();

    getContent({
      variables: {
        associate_id: auth.id,
        search_key: subject,
      },
    });
  }, [pathname]);

  return <Loading />;
};
