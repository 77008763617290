import { CategoryActionTypes } from "state/action-types/categoryTypes";

const initialState = {
  categories: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CategoryActionTypes.LIST_CATEGORIES:
      console.log(action);
      return { categories: action.payload }
    default:
      return state;
  }
};

export default reducer;