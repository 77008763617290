import { useEffect } from "react";

import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import Navbar from "components/Navbar";

import { useListParentCategories } from "hooks/useContent";
import { useSelector } from "react-redux";
import MemberPortrait from "views/Home/components/Team/MemberPortrait";
import { scrollToAnchor } from "functions/scroll";
import FullCircleSVG from "components/Graphics/FullCircleSVG";
import CircleOutlineSVG from "components/Graphics/CircleOutlineSVG";
import HalfCircleOutlineSVG from "components/Graphics/HalfCircleOutlineSVG";
import Footer from "components/Footer";

const Team = () => {
  const [listParentCategories, { loading }] = useListParentCategories();
  const {
    content: { parentCategories = [], current = [] },
  } = useSelector((content) => content);

  useEffect(() => {
    if (parentCategories.length <= 0) listParentCategories();
    scrollToAnchor("head");
  }, []);

  const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 768px)", {
    ssr: false,
  });

  return (
    <>
      <Flex
        id="head"
        direction="column"
        justify="space-between"
        w="full"
        h="100vh"
      >
        <Navbar categories={parentCategories} />
        <Box h="100%" mt="50px">
          <Flex direction="column" padding="1em">
            <Flex
              direction={{ base: "column", md: "row" }}
              align="center"
              mb="5em"
            >
              <Box
                position="relative"
                w="100%"
                align="right"
                mr={{ base: "none", md: "100px" }}
              >
                <Text fontSize="4xl">Conheça o Time Saúde 10</Text>
              </Box>
              <Box position="relative" align={{ base: "center", md: "left" }}>
                <Text
                  mt={{ base: "25px", md: "33%" }}
                  maxW={{ base: "none", md: "50%" }}
                >
                  Montamos uma equipe multidisciplinar de primeira linha, com
                  profissionais experientes e focados em cuidados preventivos
                  com a saúde. O nosso objetivo é empoderar você, para que seja
                  possível adotar hábitos saudáveis e realizar o autocuidado,
                  para ter cada vez mais qualidade de vida.
                </Text>

                {isLargerThanBreakpoint && (
                  <>
                    <Box
                      position="absolute"
                      bottom={175}
                      right={{ base: "20%", lg: "75%" }}
                    >
                      <FullCircleSVG />
                    </Box>
                    <Box
                      position="absolute"
                      zIndex={1}
                      bottom={180}
                      right={{
                        base: "calc(20% + 20px)",
                        lg: "calc(75% + 20px)",
                      }}
                    >
                      <CircleOutlineSVG />
                    </Box>
                    <Box
                      position="absolute"
                      bottom={{ base: 0, lg: 10 }}
                      right="calc(100% + 100px)"
                      transform="rotate(360deg)"
                    >
                      <HalfCircleOutlineSVG />
                    </Box>
                  </>
                )}
              </Box>
            </Flex>
            <Flex
              justify="space-around"
              direction={{ base: "column", md: "row" }}
              align="center"
              gap="48px"
            >
              <MemberPortrait
                name="Fabiana Vilela"
                position="Médica Nutróloga"
                image="/images/fabiana.webp"
              />

              <MemberPortrait
                name="Karen Sussman"
                position="Nutricionista"
                image="/images/karen.webp"
                altMarginTop="50px"
              />

              <MemberPortrait
                name="Raphael Mattos"
                position="Educador Físico"
                image="/images/raphael.webp"
              />
            </Flex>
            <Flex
              justify="space-around"
              direction={{ base: "column", md: "row" }}
              align="center"
              gap="48px"
            >
              <MemberPortrait
                name="Dr. Henrique Rosito"
                position="Médico Ortopedista e Traumatologista"
                image="/images/rosito.webp"
              />

              <MemberPortrait
                name="Dr. Rafael Linden"
                position="Doutor em Biologia Molecular e Celular"
                image="/images/rafalinden.webp"
                altMarginTop="50px"
              />

              <MemberPortrait
                name="Dra. Mariele Charão"
                position="Doutora em Ciências Farmacêuticas"
                image="/images/mariele.webp"
              />
            </Flex>
            <Flex
              justify="space-around"
              direction={{ base: "column", md: "row" }}
              align="center"
              gap="48px"
            >
              <MemberPortrait
                name="Viviane Macedo"
                position="Nutricionista"
                image="/images/viviane.png"
              />

              <MemberPortrait
                name="Any Figueiredo"
                position="Técnica de Enfermagem"
                image="/images/any.png"
                altMarginTop="50px"
              />

              <MemberPortrait
                name="Ieda Herculano"
                position="Psicóloga"
                image="/images/ieda.png"
              />
            </Flex>
            <Flex
              justify="space-around"
              direction={{ base: "column", md: "row" }}
              align="center"
              gap="48px"
            >
              <MemberPortrait
                name="Larissa Nogueira"
                position="Fisioterapeuta"
                image="/images/larissa.png"
              />
              <MemberPortrait
                name="Luiza Fornazelli"
                position="Técnica de Enfermagem"
                image="/images/luiza.png"
              />
            </Flex>
          </Flex>
          <Footer />
        </Box>
      </Flex>
    </>
  );
};

export default Team;