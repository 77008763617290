import { Center, Spinner } from "@chakra-ui/react";
const Loading = () => {
	return (
		<Center h="100vh">
			<Spinner
				thickness="7px"
				speed="0.65s"
				emptyColor="gray.200"
				color="appai.main"
				w="120px"
				h="120px"
			/>
		</Center>
	);
};

export default Loading