import { Box, Image as ChakraImage } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const FadingImage = ({ src, alt = "" }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    
    img.src = src;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, [src]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={imageLoaded ? { opacity: 1 } : {}}
      transition={{ duration: 0.5 }}
    >
      {imageLoaded ? (
        <ChakraImage
          boxSize={{ base: "400px", md: "500px" }}
          borderRadius="5px"
          fit="cover"
          src={src}
          alt={alt}
        />
      ) : (
        <Box
          boxSize={{ base: "400px", md: "500px" }}
          borderRadius="5px"
          bg="grey.600"
        />
      )}
    </motion.div>
  );
};

export default FadingImage;
