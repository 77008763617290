import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import Icon from './Icon'

const Health = () => {
    const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 1000px)", {
      ssr: false,
    });

    return (
        <Flex w="100%" justify="space-between" paddingY="5em" bg="white">
            <Flex direction="column" w="full">
                <Box ml={{ base: "20px", md: "150px" }} mb="50px">
                    <Text
                        fontWeight="thin"
                        fontSize="sm"
                        color="gray.500"
                        letterSpacing="2px"
                    >
                        Programa Saúde 10
                    </Text>
                    <Text fontWeight="bold" fontSize={{ base: "xl", md: "2xl" }}>
                        Conheça os demais pilares para você curtir e aproveitar a vida.
                    </Text>
                </Box>
                <Flex
                    w="full"
                    direction={isLargerThanBreakpoint ? "row" : "column"}
                    justifyContent="space-around"
                >
                    <Icon
                        title="O Programa"
                        icon={1}
                        label="Quero saber mais"
                    />
                    <Icon
                        title="Roda de Saúde"
                        icon={2}
                        label="Quero conhecer"
                    />
                    <Icon
                        title="Encontro Saúde 10"
                        icon={3}
                        label="Quero me inscrever"
                    />
                    <Icon
                        title="Oficina de Psicologia"
                        icon={4}
                        label="Quero participar"
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Health