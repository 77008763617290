import { Button, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import healthid from 'assets/images/logo_healthid_ex_w.png'
import AcceptTerms from "components/AcceptTerms";

const Footer = () => {
	const {
		isOpen: termsOpen,
		onOpen: termsOnOpen,
		onClose: termsOnClose,
	} = useDisclosure({defaultIsOpen: false});

	return (
		<Flex
			bg="appai.secondaryText"
			minH="fit-content"
			align="center"
			justify="center"
			paddingY="2.5em"
			direction={{ base: "column", lg: "row" }}
		>
			<Image
				src="/images/logo_appai_white.svg"
				maxW="200px"
				mr={{ md: "0", lg: "1em" }}
				mb={{ base: "0.5em", md: "0" }}
			/>
			<Text
				color="white"
				fontSize={{ base: "md", md: "lg" }}
				mt="1em"
				mr="1em"
				mb={{ sm: "1em", md: "0" }}
			>
				em parceria com
			</Text>
			<Image
				src={healthid}
				maxW="200px"
				mr={{ md: "0", lg: "5em" }}
				mb={{ base: "2em", md: "0" }}
			/>
			<Flex direction="column" align={{ base: "center", md: "start" }}>
				<Text
					color="gray.300"
					fontWeight="thin"
					fontSize={{ base: "lg", md: "xl" }}
					letterSpacing="1.5px"
				>
					Empodere-se da sua saúde
				</Text>
				<Text color="white" fontSize={{ base: "md", md: "lg" }}>
					Apoio ao associado: (21) 3983-3200 (21) 3514-0800
				</Text>
				<Text
					color="gray.300"
					fontWeight="thin"
					fontSize={{ base: "md", md: "lg" }}
					letterSpacing="1.5px"
				>
					Informações sobre os conteúdos: (11) 95555-4624
				</Text>
				<Button variant="link" onClick={termsOnOpen}>
					<Text
						color="white"
						fontWeight="thin"
						fontSize={{ base: "md", md: "lg" }}
						letterSpacing="1.5px"
					>
						Política de Privacidade
					</Text>
					<AcceptTerms
						preview={true}
						isOpen={termsOpen}
						onOpen={termsOnOpen}
						onClose={termsOnClose}
					/>
				</Button>
			</Flex>
		</Flex>
	);
};

export default Footer;