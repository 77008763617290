import Header from "./components/Header";
import About from "./components/About";
import Team from "./components/Team";
import Footer from "components/Footer";
import Categories from "components/Categories";
import Health from "./components/Health";
import Carousel from "./components/Carousel";

export default ({ categories }) => {
  return (
    <>
      <Header />
      <Carousel />
      <About />
      <Categories categories={categories} />
      <Team />
      <Health />
      <Footer />
    </>
  );
};
