import { Box } from "@chakra-ui/react";

export default () => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="30 25.5 139.9 149"
      viewBox="30 25.5 139.9 149"
      height="100"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="presentation"
      aria-hidden="true"
      aria-label=""
    >
      <g>
        <path
          d="M134.8 174.5C77 174.5 30 127.3 30 69.3c0-15.3 3.2-30 9.5-43.8l.9.4C34.2 39.6 31 54.2 31 69.3c0 57.5 46.6 104.2 103.8 104.2 12 0 23.7-2 34.8-6l.3.9c-11.2 4.1-23 6.1-35.1 6.1z"
          fill="#010203"
          data-color="1"
        ></path>
      </g>
    </svg>
  );
};
