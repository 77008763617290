import { Box, Flex, Image, Link, useMediaQuery } from "@chakra-ui/react";
import NavButton from "./components/NavButton";
import { HouseSimple, List } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import Dropdown from "./components/Dropdown";
import { useListCategories } from "hooks/useContent";
import { useSelector } from "react-redux";

const Navbar = ({ }) => {
	const { categories } = useSelector(({ category }) => category);
	const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 1100px)", {
		ssr: false,
	});
	const [isLargeThanMobile] = useMediaQuery("(min-width: 600px)", {
		ssr: false,
	});
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const { loading } = useListCategories();

	useEffect(() => {
		if(isLargeThanMobile) setDropdownOpen(false)
	}, [isLargeThanMobile])

	return (
		<>
			<Box
				position="fixed"
				display="flex"
				justifyContent="space-between"
				align="center"
				w="100%"
				minH="100px"
				bg="appai.lightGrey"
				padding="1em .5em"
				boxShadow="0px 10px 15px #00000050;"
				zIndex={99}
			>
				<Flex padding=".25em">
					<Link href="/">
						<Image
							src="/images/logo_appai.svg"
							minW="160px"
							marginX={{ base: "10px", sm: "25px", md: "100px" }}
							alt="SVG Image"
						/>
					</Link>
					{isLargerThanBreakpoint && (
						<Flex
							position="absolute"
							bottom="-30px"
							right={{ base: "0", md: "0", lg: "2em", xl: "10em" }}
							align="center"
							boxShadow="0px 5px 10px #00000050;"
						>
							<Flex>
								<Link href="/">
									<NavButton
										style={{
											padding: { base: "1em", xl: "1.5em 2em" },
											minW: "70px",
										}}
									>
										<HouseSimple weight="fill" size={28} />
									</NavButton>
								</Link>
								{categories?.map((category, index) => (
									<NavButton
										url={category?.url_safe_title}
										last={index + 1 === categories.length}
									>
										{category?.title}
									</NavButton>
								))}
							</Flex>
						</Flex>
					)}
				</Flex>
			</Box>

			{!isLargerThanBreakpoint && (
				<Flex
					position="fixed"
					mt="110px"
					w="full"
					bg="appai.main"
					align="center"
					justify={isLargeThanMobile ? "center" : "space-between"}
					zIndex={98}
				>
					<Link href="/">
						<NavButton
							style={{
								padding: "1em",
								minW: "70px",
							}}
						>
							<HouseSimple weight="fill" size={24} />
						</NavButton>
					</Link>
					{isLargeThanMobile ?
						categories?.map((category, index) => {
							return (
								<NavButton
									url={category?.url_safe_title}
									last={index + 1 === categories.length}
								>
									{category?.title}
								</NavButton>
							);
						})
						: <Box
							display="flex"
							position="relative"
							px="1em"
							minW="70px"
							onClick={() => setDropdownOpen(!dropdownOpen)}
						>
							<List weight="fill" size={24} color="white" />
						</Box>
					}
				</Flex>
			)}
			<Dropdown isOpen={dropdownOpen} setIsOpen={setDropdownOpen} categories={categories} />
		</>
	);
};

export default Navbar