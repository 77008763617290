import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Button,
  useDisclosure,
  Flex,
  IconButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { Download } from "@phosphor-icons/react";

import "pdfjs-dist/build/pdf";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default ({ title, file }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabletSize, desktopSize] = useMediaQuery([
    "(min-width: 540px)",
    "(min-width: 1100px)",
  ]);

  const [documentScale, setDocumentScale] = useState(0.75);

  useEffect(() => {
    setDocumentScale(0.75)
    if (tabletSize) setDocumentScale(1);
    if (desktopSize) setDocumentScale(1);
  }, [tabletSize, desktopSize]);

  useEffect(() => {
    const pdfjsLib = window["pdfjs-dist/build/pdf"];
    const pdfjsWorker = window["pdfjs-dist/build/pdf.worker"];

    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    if (isOpen) {
      pdfjsLib
        .getDocument(file)
        .promise.then((pdfDoc) => {
          for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
            pdfDoc.getPage(pageNum).then((page) => {
              const container = document.getElementById("pdf-container");
              const canvas = document.createElement("canvas");
              container.appendChild(canvas);

              const modalWidth = document.getElementById("modal-body").offsetWidth;
              const pageWidth = page.view[2];

              const realScale = (modalWidth - 50) / pageWidth;

              const viewport = page.getViewport({
                scale: realScale,
              });

              const canvasContext = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width ;

              page.render({
                canvasContext,
                viewport,
              });
            });
          }
        })
        .catch((error) => {
          console.error("Error loading PDF: ", error);
        });
    }
  }, [file, isOpen, documentScale]);

  return (
    <>
      <Center>
        <Button
          marginY="1em"
          padding="1em 5em"
          colorScheme="purple"
          onClick={onOpen}
        >
          Download
        </Button>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose} size={{base: "3xl", xl: "5xl"}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex align="center">
              {title}
              <Link to={file} target="_blank" rel="noreferrer">
                <IconButton
                  icon={<Download size={24} />}
                  color="gray.700"
                  marginLeft="1.5em"
                />
              </Link>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody id="modal-body" h="100vh">
            <div id="pdf-container" style={{ height: "100%" }}></div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
