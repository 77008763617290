import { Button } from "@chakra-ui/react";

export default ({ children, onClick = () => {}, style = {} }) => {
  return (
    <Button
      maxW={{ base: "none", sm: "75%" }}
      padding="1.5em 2em"
      borderRadius="25px"
      border={{ base: "2px solid #623D80", lg: "2px solid #C1AED4" }}
      bg={{ base: "appai.main", lg: "appai.buttonGrey" }}
      color={{ base: "white", lg: "appai.secondaryText" }}
      _hover={{
        bg: "appai.main",
        borderColor: "appai.darker",
        color: "white",
      }}
      transition=".5s ease"
      onClick={onClick}
      style={style}
    >
      {children}
    </Button>
  );
};
