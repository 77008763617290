import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import PrimaryButton from "components/PrimaryButton";
import MemberPortrait from "./MemberPortrait";

import { useNavigate } from "react-router-dom";

export default () => {
	const navigate = useNavigate();

	return (
		<Flex
			w="100%"
			padding={{ base: "1em 2em", md: "2.5em 5em" }}
			direction="column"
			bg="white"
		>
			<Box
				display="flex"
				flexDirection="column"
				bg="appai.light"
				maxW={{ base: "none", lg: "60%" }}
				padding="2.5em"
				mb="75px"
			>
				<Text
					fontWeight="thin"
					fontSize="sm"
					color="gray.500"
					letterSpacing="2px"
					mb="10px"
				>
					Time Saúde 10
				</Text>
				<Text fontWeight="bold" fontSize="2xl" mb="25px">
					Equipe multidisciplinar
				</Text>
				<Text fontSize="lg" mb="35px">
					Unimos profissionais qualificados para entregar conteúdos didáticos e objetivos.
					O Programa conta com uma equipe especializada e interdisciplinar que desenvolve mensalmente conteúdos para o dia a dia,
					palestras, grupos, oficinas, rodas de saúde e lives.
				</Text>
				<PrimaryButton
					onClick={() => navigate("/time-saude")}
					style={{ alignSelf: "flex-end" }}
				>
					Conhecer o time
				</PrimaryButton>
			</Box>
			<Flex
				justify="space-around"
				direction={{ base: "column", md: "row" }}
				align="center"
				gap="32px"
			>
				<MemberPortrait
					name="Any Figueiredo"
					position="Técnica de Enfermagem"
					image="/images/any.png"
				/>

				<MemberPortrait
					name="Karen Sussman"
					position="Nutricionista"
					image="/images/karen.webp"
					altMarginTop="50px"
				/>

				<MemberPortrait
					name="Raphael Mattos"
					position="Educador Físico"
					image="/images/raphael.webp"
				/>
			</Flex>
		</Flex>
	);
};
