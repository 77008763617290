import { Box, Image, Text } from "@chakra-ui/react";

export default ({ name, position, image, altMarginTop = "none" }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      position="relative"
      maxH="440px"
      w="320px"
      mb="100px"
      mt={altMarginTop}
    >
      <Image
        objectFit="cover"
        src={image}
        minW={{ base: "240px", lg: "275px" }}
        minH={{ base: "300px", lg: "330px" }}
        maxW={{ base: "260px", lg: "300px" }}
        maxH={{ base: "330px", lg: "360px" }}
        style={{
          mask: 'url(\'data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8"%3F%3E%3Csvg preserveAspectRatio="none" data-bbox="47.1 20 105.9 160" viewBox="47.1 20 105.9 160" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="shape"%3E%3Cg%3E%3Cpath d="M100 20c-29.2 0-52.9 23.7-52.9 52.9V180H153V72.9c-.1-29.2-23.8-52.9-53-52.9z"/%3E%3C/g%3E%3C/svg%3E%0A\')',
          maskPosition: "0% 0%",
          maskSize: "100% 100%",
          maskRepeat: "no-repeat",
          borderRadius: "150px 150px 0 0",
        }}
      ></Image>
      <Box
        position="absolute"
        padding={{ base: "1em 1.5em", lg: "1.5em 3em" }}
        bg="appai.light"
        minW="240px"
        maxW="260px"
        bottom="-25px"
      >
        <Text fontSize="lg" color="gray.600" fontWeight="thin" translate="no">
          {name}
        </Text>
        <Text fontSize="xl">{position}</Text>
      </Box>
    </Box>
  );
};
