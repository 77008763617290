import { gql } from '@apollo/client';

export const GET_CAROUSEL = gql`
	query GetCarousel {
		listCarousel {
			id
			title
			order
			imports {
				id
				type
				filename
				key
			}
		}
	}
`;