import { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Box, Flex, Image, Text } from "@chakra-ui/react";
import "../markdown.css";

import PDFModal from "components/PDFModal";

export default ({ post }) => {
  const [templateFields, setTemplateFields] = useState({});
  const [download, setDownload] = useState(false);

  useEffect(() => {
    setTemplateFields(JSON.parse(post?.template_fields));
    setDownload(post?.imports?.find((i) => i?.type === "download")?.key);
  }, [post]);

  return (
    <Flex direction="column" h="100vh" id="post">
      <Box position="relative" h="520px">
        <Image
          w="100vw"
          maxH="520px"
          fit="cover"
          src={post?.imports?.find((i) => i?.type === "cover")?.key}
        />

        <Box
          position="absolute"
          w="100%"
          h="100%"
          top="0"
          left="0"
          bgGradient="linear(to top, rgba(0, 0, 0, 0.7), transparent)"
        />

        <Text
          position="absolute"
          bottom="60px"
          left="100px"
          fontWeight="regular"
          fontSize={{ base: "32px", md: "36px" }}
          color="white"
        >
          {post?.title}
        </Text>
        {post?.subtitle && (
          <Text
            position="absolute"
            bottom="20px"
            left="100px"
            fontWeight="thin"
            fontSize={{ base: "24px", md: "28px" }}
            color="gray.100"
          >
            {post?.subtitle}
          </Text>
        )}
      </Box>
      <Box marginX="5%">
        <ReactMarkdown
          children={templateFields?.body}
          remarkPlugins={[remarkGfm]}
        ></ReactMarkdown>

        {download && <PDFModal title={post?.title} file={download} />}
      </Box>
    </Flex>
  );
};
