import { createBrowserRouter } from "react-router-dom";

import Root from "views/Root";
import Login from "views/Login";
import SearchRoute from "views/SearchRoute";
import NotFound from "views/Errors/NotFound";
import Team from "views/Team";
import Draft from "views/Draft";
import Post from "views/Post";
import Category from "views/Category";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    errorElement: <SearchRoute />,
    children: [
      {
        path: "/",
        element: <Root />,
      },
    ],
  },
  {
    path: "/:cat",
    element: <Category />,
  },
  {
    path: "/:cat/:post",
    element: <Post />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/time-saude",
    element: <Team />,
  },
  {
	path: "/rascunho/:id",
	element: <Draft />
  },
  {
    path: "/not-found",
    element: <NotFound />,
  },
]);

export { router };
