import { useNavigate } from "react-router-dom";
import {
	Box,
	Button,
	Center,
	Flex,
	Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { login } from "services/appaiAuth";
import Loading from "components/Loading";

const Login = () => {
	const [isLoading, setIsLoading] = useState(false);

	const appaiLogin = () => {
		setIsLoading(true)
		login();
	}

	useEffect(() => {
		appaiLogin();
	}, [])

	return <Loading />

	return (
		<Flex height="100vh" justfiy="center" align="center" bg="appai.main">
			<Center bg="white" h="100%" w={{ base: "80%", md: "60%", lg: "33%" }}>
				<Box w="75%">
					<Image margin="auto" maxW="350px" src="/appai_logo.svg" mb="5em" />
					<Button
						onClick={appaiLogin}
						colorScheme="purple"
						width="100%"
						isLoading={isLoading}
						disabled={isLoading}
					>
						Entrar
					</Button>
				</Box>
			</Center>
		</Flex>
	);
};

export default Login;
