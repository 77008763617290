import { Button, Collapse, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const Dropdown = ({ isOpen, setIsOpen, categories }) => {
    const navigate = useNavigate();

    return (
        <Collapse in={isOpen} zIndex={98}>
            <Flex
                position="fixed"
                mt="180px"
                w="full"
                bg="appai.main"
                align="center"
                borderTop="1px solid #623D80"
                zIndex={99}
            >
                <Flex
                    w="full"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    {
                        categories?.map(cat =>
                            <Button
                                display="flex"
                                position="relative"
                                color="white"
                                bg="appai.main"
                                onClick={() => navigate(`/${cat?.url_safe_title}`)}
                            >{cat.title}</Button>
                        )
                    }
                </Flex>
            </Flex>
        </Collapse>
    )
}

export default Dropdown