import { UserManager } from "oidc-client";

// const settings = {
//     authority: "https://segurancaapi-tes.appai.org.br",
//     client_id: "Blog_Saude_Client",
//     //redirect_uri: `${process.env.REACT_APP_APPAI_CLIENT_ROOT}signin-callback.html`,
//     //silent_redirect_uri: `${process.env.REACT_APP_APPAI_CLIENT_ROOT}silent-renew.html`,
//     redirect_uri: `https://hom.app.appai.healthidlab.com.br/signin-callback.html`,
//     silent_redirect_uri: `https://hom.app.appai.healthidlab.com.br/silent-renew.html`,
//     // tslint:disable-next-line:object-literal-sort-keys
//     post_logout_redirect_uri: `https://hom.app.appai.healthidlab.com.br`,
//     response_type: 'id_token token',
//     scope: "openid email roles profile"
// };

const settings = {
    authority: process.env.REACT_APP_APPAI_STS_AUTHORITY,
    client_id: process.env.REACT_APP_APPAI_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_APPAI_CLIENT_ROOT}/signin-callback.html`,
    silent_redirect_uri: `${process.env.REACT_APP_APPAI_CLIENT_ROOT}/silent-renew.html`,
    post_logout_redirect_uri: `${process.env.REACT_APP_APPAI_CLIENT_ROOT}`,
    response_type: 'id_token token',
    scope: process.env.REACT_APP_APPAI_CLIENT_SCOPE
};

console.log('Setting: ', settings);
console.log('response: ', settings.response_type);
console.log('scope: ', settings.scope);

const userManager = new UserManager(settings);

const getUser = () => {
    return userManager.getUser();
}

const login = () => {
    try {
        const response = userManager.signinRedirect();
        return response;
    } catch(error) {
        console.log(error);
        debugger
    }
}

const renewToken = () => {
    return userManager.signinSilent();
}

const logout = () => {
    return userManager.signoutRedirect();
}

export {
    getUser,
    login,
    renewToken,
    logout,
}