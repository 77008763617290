import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import { useCarousel } from "hooks/useCarousel";
import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

import './carousel.css';

const carouselConfig = (breakpoint) => ({
  showThumbs: false,
  showStatus: false,
  dynamicHeight: true,
  centerMode: breakpoint,
  centerSlidePercentage: breakpoint ? 33.33 : 100,
});

const LeftArrowButton = ({ onClick }) => {
  return (
    <Flex
      position="absolute"
      justifyContent="center"
      alignItems="center"
      left="2"
      top="45%"
      zIndex={2}
      onClick={onClick}
      height="30px"
      width="30px"
      borderRadius="full"
      border="2px solid black"
      backgroundColor="white"
      cursor="pointer"
    >
      <ArrowLeft size="22" />
    </Flex>
  );
};

const RightArrowButton = ({ onClick }) => {
  return (
    <Flex
      position="absolute"
      justifyContent="center"
      alignItems="center"
      right="2"
      top="45%"
      zIndex={2}
      onClick={onClick}
      height="30px"
      width="30px"
      borderRadius="full"
      border="2px solid black"
      backgroundColor="white"
      cursor="pointer"
    >
      <ArrowRight size="22" />
    </Flex>
  );
};

const Carousel = () => {
  const { loading } = useCarousel();
  const images = useSelector(({ carousel }) => carousel);

  const screenWidth = window.screen.width;

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageChange = (index) => {
    setCurrentIndex(index);
  };

  const copyImages = [...images];

  const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 984px)", {
    ssr: false,
  });

  const boxHeight = isLargerThanBreakpoint ? screenWidth / 3 : screenWidth;

  if (loading) return <></>;
  if (copyImages.length == 0) return <></>;

  return (
    <Box mt="2em">
      <ResponsiveCarousel
        onChange={handleImageChange}
        {...carouselConfig(isLargerThanBreakpoint)}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && <LeftArrowButton onClick={onClickHandler} direction="prev" />
        }
        renderArrowNext={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <RightArrowButton onClick={onClickHandler} direction="next" />
          )
        }
      >
        {loading ? (
          <div></div>
        ) : (
          copyImages
            ?.sort((a, b) => (a.order > b.order ? 1 : -1))
            ?.map((img, x) => (
              <Box h={`${boxHeight}px`}>
                <Image h={`${boxHeight}px`} src={img?.imports[0]?.key} className={`has-blur ${x == currentIndex ? "" : "blur-image"}`} />
              </Box>
            ))
        )}
      </ResponsiveCarousel>
    </Box>
  );
};

export default Carousel;
