import {
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { modalCreators } from "state";

const AppaiModal = ({isOpen, onClose}) => {
  const dispatch = useDispatch();
  const { openClose } = bindActionCreators(modalCreators, dispatch);
  const handleClose = () => {
    openClose();
    onClose();
  }
  return (
    <Modal size="3xl" onClose={handleClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="lg" color="black" backgroundColor="white" />
        <Image src="/images/appai_modal.png" w="full" h="full" />
      </ModalContent>
    </Modal>
  );
};

export default AppaiModal;
