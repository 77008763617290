import { CarouselActionType } from "../action-types/carouselTypes";

export const listCarousel = (images) => {
  return (dispatch) => {
    dispatch({
      type: CarouselActionType.LIST_CAROUSEL,
      payload: images,
    });
  };
};
