import React, { useEffect, useState } from "react";
import BasicPost from "./Templates/BasicPost";
import VideoPost from "./Templates/VideoPost";
import { useGetContent } from "hooks/useContent";
import { useParams } from "react-router-dom";
import Footer from "components/Footer";
import Header from "views/Home/components/Header";
import Navbar from "components/Navbar";
import { Box, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default ({ draftPost = null }) => {
  const { post } = useParams();
  const auth = useSelector(({ auth }) => auth);
  const [renderComponent, setRenderComponent] = useState("");
  const [getContent, { data, loading }] = useGetContent();

  useEffect(() => {
    getContent({
      variables: {
        search_key: post,
        associate_id: auth.id
      },
    });
  }, []);

  const templates = [
    { title: "basic_post", component: BasicPost },
    { title: "video_post", component: VideoPost },
  ];

  React.useEffect(() => {
    if (draftPost !== null) {
      const component = templates?.find(
        (t) => t?.title === draftPost?.template?.title
      )?.component;

      setRenderComponent(React.createElement(component, { post: draftPost }));
    } else if (data?.searchContent) {
      const { post } = JSON.parse(JSON.parse(data.searchContent));
      const component = templates?.find(
        (t) => t?.title === post?.template?.title
      )?.component;

      setRenderComponent(React.createElement(component, { post: post }));
    }
  }, [data, draftPost]);

  return (
    <>
      <Flex direction="column" justify="space-between" w="full" h="100vh">
        <Navbar />
        <Box mt={{ base: "0", md: "110px" }} h="100%">
          {renderComponent}
        </Box>
      </Flex>
    </>
  );
};
