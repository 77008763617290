import { createContext, useContext } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";

import { contentCreators } from "state";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { GET_CATEGORIES, GET_CONTENT } from "graphql/content";
import { GET_POST } from "graphql/content";
import { categoryCreators } from "state";

const CategoriesStateContext = createContext();
const CategoriesDispatchContext = createContext();

const CategoriesProvider = ({ children }) => {
  return (
    <CategoriesStateContext.Provider>
      <CategoriesDispatchContext.Provider>
        {children}
      </CategoriesDispatchContext.Provider>
    </CategoriesStateContext.Provider>
  );
};

const useContent = () => {
  const context = useContext(CategoriesStateContext);

  if (!context || context === undefined)
    throw new Error("useContent must be used within an ContentProvider");

  return context;
};

const useListCategories = () => {
  const dispatch = useDispatch();
  const { listCategories } = bindActionCreators(categoryCreators, dispatch);

  const variables = {
    where: {
      column: "TYPE",
      operator: "EQ",
      value: "menu",
    },
  };

  return useQuery(GET_CATEGORIES, {
    variables: variables,
    onCompleted: ({ listCategories: categories }) => {
      console.log("on completed", categories)
      listCategories(categories);
    },
  });
}

const useListTopics = (key) => {
  return useQuery(GET_CONTENT, {
    variables: {
      search_key: key
    },
    fetchPolicy: "no-cache",
    onCompleted: ({ searchContent }) => {
    },
    onError: () => {},
  });
};

const useListParentCategories = () => {
  const dispatch = useDispatch();
  const { getParentCategories } = bindActionCreators(contentCreators, dispatch);
  const variables = {
    where: {
      column: "TYPE",
      operator: "EQ",
      value: "menu",
    },
  };

  return useLazyQuery(GET_CATEGORIES, {
    variables: variables,
    onCompleted: ({ listCategories }) => {
      getParentCategories(listCategories);
    },
  });
};

const useGetContent = (redirect) => {
  const dispatch = useDispatch();
  const { getContent } = bindActionCreators(contentCreators, dispatch);

  const {
    content: { breadcrumbs = [] },
  } = useSelector((content) => content);

  return useLazyQuery(GET_CONTENT, {
    fetchPolicy: "no-cache",
    onCompleted: ({ searchContent }) => {
      const pathname = window.location.pathname;
      redirect("/");
      console.log(redirect, JSON.parse(searchContent));
      const joined = breadcrumbs.join("/");
      console.log(joined);
      window.history.replaceState(null, "", pathname);

      getContent(JSON.parse(JSON.parse(searchContent)));
    },
    onError: () => redirect("/not-found"),
  });
};

const useGetDraft = (id) => {
  return useQuery(GET_POST, {
    variables: {
      id
    }
  })
}

export {
  CategoriesProvider,
  useListCategories,
  useContent,
  useListParentCategories,
  useListTopics,
  useGetContent,
  useGetDraft
};
