import { ApolloClient, HttpLink, from, InMemoryCache } from "@apollo/client";

const createClient = () => {
  const HTTP_LINK = new HttpLink({
    uri: process.env.REACT_APP_API_URL + "/graphql",
    headers: {
      authorization: "Bearer " + process.env.REACT_APP_APPAI_AUTH_TOKEN || '1mgD8srorUpT7t1hZt0D4txUDkBjffXtmOYC1oqld0z5AmlDgzjl9AAvx4T3VUeK',
      api: "Bearer " + process.env.REACT_APP_APPAI_AUTH_TOKEN || '1mgD8srorUpT7t1hZt0D4txUDkBjffXtmOYC1oqld0z5AmlDgzjl9AAvx4T3VUeK',
    },
  });

  const APP_LINK = from([HTTP_LINK]);

  return new ApolloClient({
    link: APP_LINK,
    cache: new InMemoryCache(),
  });
};
export default createClient;
