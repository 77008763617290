import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import AppaiModal from "components/AppaiModal";
import { dateFormat } from "functions/dateFormat";
import { useAcceptTerms } from "hooks/useAuth";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AcceptTerms = ({ isOpen, onOpen, onClose, preview = false }) => {
  const { isOpen: modalOpen, onClose: modalClose } = useDisclosure({
    defaultIsOpen: true,
  });
  const auth = useSelector(({ auth }) => auth);
  const modal = useSelector(({ modal }) => modal);
  const [submitAccept, { loading }] = useAcceptTerms();

  useEffect(() => {
    if (auth && auth.id != null && auth.accepted_at == null && !preview) {
      onOpen();
    }
  }, [onOpen]);

  const onAccept = () => {
    const now = new Date();
    const data = { accept: true, accepted_at: new Date() };
    const variables = {
      id: auth.id,
      input: {
        accepted_at: dateFormat(now, "yyyy-MM-dd HH:mm:ss"),
      },
    };
    submitAccept({ variables });
    // window.localStorage.setItem("@acceptTerms", JSON.stringify(data))
    onClose();
  };
  return (
    <>
      <AppaiModal
        isOpen={modalOpen && modal.open && auth.accepted_at == null}
        onClose={modalClose}
      />
      <Modal size="md" isOpen={isOpen && !modal.open} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="lg" color={useColorModeValue("black", "white")}>
              Política de Privacidade e Proteção de Dados
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box maxH="400px" overflowY="auto">
              <Text fontSize="lg" color={useColorModeValue("black", "white")}>
                <Text fontWeight="bold" mb="8px">
                  Introdução
                </Text>
                <Text mb="8px">
                  Esta Política de Privacidade regulamenta o tratamento de dados
                  pessoais de clientes, colaboradores, parceiros, usuários
                  finais dos clientes da APPAI (Associação Beneficente dos
                  Professores Públicos Ativos e Inativos do Estado do Rio de
                  Janeiro) sob a responsabilidade exclusiva da HEALTH ID.
                </Text>

                <Text mb="8px">
                  A presente Política de Privacidade representa o nosso
                  compromisso com a transparência, a integridade e o respeito à
                  privacidade, à proteção e à segurança dos dados pessoais dos
                  usuários e de nossos stakeholders, em particular dos
                  associados da APPAI, parceiros e restantes dos titulares dos
                  dados que estejam sob a nossa guarda.
                </Text>

                <Text mb="8px">
                  Por favor leia atentamente esta política antes de usar ou
                  obter quaisquer materiais, informações, produtos ou serviços
                  da empresa. Nos reservamos o direito de alterar, atualizar ou
                  corrigir esta política a qualquer momento. Ao usar nossos
                  serviços, você consente com a nossa Política de Privacidade e
                  Proteção de Dados e com a coleta, uso e compartilhamento de
                  suas informações conforme descrito abaixo.
                </Text>

                <Text mb="8px" fontWeight="bold">
                  Entidade Responsável pelo tratamento de Dados
                </Text>

                <Text mb="8px">
                  A entidade responsável pelo tratamento dos dados é a HEALTH ID
                  LTDA, inscrita no CNPJ sob o número 34.037.799/0001-02, com
                  sede na Av. Cristóvão Colombo, n° 1757, Porto Alegre, RS, CEP
                  90560-003. Você pode contatar o nosso Encarregado de Dados
                  (DPO), através do e-mail guilherme@clichealthid.com
                </Text>

                <Text mb="8px" fontWeight="bold">
                  Princípios observados
                </Text>

                <Text mb="8px">
                  Toda informação pessoal identificável que você nos fornecer
                  será usada somente de acordo com o permitido por lei, bem como
                  para disponibilizar conteúdos qualificados sobre qualidade de
                  vida, longevidade, saúde mental para os associados (as) da
                  APPAI.
                </Text>

                <Text mb="8px">
                  Seus dados pessoais não serão vendidos, alugados ou utilizados
                  para outros fins. A HEALTH ID também observa as práticas
                  recomendadas de Segurança da Informação e adota medidas
                  técnicas, organizacionais e administrativas para proteger os
                  dados pessoais de acessos não autorizados e de situações
                  acidentais ou ilícitas de destruição, perda, alteração,
                  comunicação ou difusão, bem como para atuar de conformidade
                  com a Lei Geral de Proteção de Dados Pessoais (Lei nº
                  13.709/18) e demais regulamentos sobre a matéria.
                </Text>

                <Text mb="8px">
                  O acesso aos dados dos associados (as), bem como o
                  compartilhamento de conteúdos para obter mais qualidade de
                  vida, visam tão somente criar condições e oportunidades para
                  proporcionar a busca do aperfeiçoamento contínuo qualidade de
                  vida e lazer dos filiados e seus beneficiários, como cidadãos
                  e profissionais, além de colaborar para o desenvolvimento e a
                  manutenção do equilíbrio social.
                </Text>

                <Text mb="8px" fontWeight="bold">
                  DADOS SENSÍVEIS
                </Text>
                <Text mb="8px">
                  Importante ressaltar que os Dados de Saúde dos Associados são
                  considerados dados sensíveis pela legislação brasileira e, por
                  isso, merecem uma proteção diferenciada. Ainda, esses dados
                  estão sujeitos a regras de sigilo estabelecidas por normas
                  setoriais e autoridades competentes. A HEALTH ID reconhece a
                  importância e sensibilidade desses dados e informa que os
                  mesmos serão tratados seguindo regras criteriosas que garantam
                  o cumprimento das legislações aplicáveis.
                </Text>

                <Text mb="8px" fontWeight="bold">
                  Que dados pessoais são recolhidos e tratados?
                </Text>

                <Text mb="8px">
                  A HEALTH ID poderá recolher e tratar, entre outros, os
                  seguintes dados pessoais:
                </Text>
                <Text mb="8px">
                  a) Questionário: nome, data de nascimento, peso, altura,
                  gênero, estado geral de saúde, atividades físicas que realiza,
                  consumo de alimentos, consumo de bebida alcoólica, tabagismo,
                  atividades de lazer e saúde mental.
                </Text>
                <Text mb="8px">
                  b) Acesso a plataforma digital: número de acessos, conteúdos
                  acessados, periodicidade.
                </Text>

                <Text mb="8px" fontWeight="bold">
                  De que forma os seus dados são coletados?
                </Text>
                <Text mb="8px">
                  Os dados pessoais podem ser coletados através dos seguintes
                  meios:
                </Text>
                <Text mb="8px">a) Website e formulários online;</Text>
                <Text mb="8px">
                  Alguns dados pessoais recolhidos são de preenchimento
                  obrigatório e necessários. A HEALTH ID irá informá-lo sobre a
                  natureza obrigatória do fornecimento destes dados pessoais e a
                  finalidade de sua utilização.
                </Text>

                <Text mb="8px" fontWeight="bold">
                  Para que a HEALTH ID coleta dados pessoais?
                </Text>
                <Text mb="8px">
                  A HEALTH ID trata os dados pessoais dos associados da APPAI
                  com a finalidade principal de disponibilizar conteúdos
                  qualificados sobre qualidade de vida, longevidade, saúde
                  mental para os associados (as) da APPAI.
                </Text>
                <Text mb="8px" fontWeight="bold">
                  Com quem a HEALTH ID compartilha os dados coletados?
                </Text>
                <Text mb="8px">
                  Os dados pessoais dos associados (as) poderão ser
                  compartilhados somente com a APPAI, sendo especificamente os
                  gestores do programa saúde 10. Do referido compartilhamento, a
                  APPAI somente pode utilizar os dados com a finalidade de
                  colaborar não só com o respectivo quadro associativo, mas
                  também com as políticas públicas voltadas para o setor da
                  Educação e, ainda, para a própria classe profissional, dentro
                  do seu papel de Entidade de utilidade pública, considerando a
                  relevância de suas atividades, que vão além da beneficência
                  voltada para os filiados integrantes do referido quadro. Neste
                  contexto e nos limites também dos interesses legítimos da
                  Pessoa Jurídica sem Fins Econômicos e/ou Lucrativos, a
                  Associação desenvolveu e vem aperfeiçoando seu sítio para
                  atender a esta finalidade, sendo todos os dados, informações e
                  tratamentos realizados pela APPAI feitos em conformidade com
                  todo o ordenamento jurídico brasileiro, especialmente
                  respeitando a CFRB/1988, o CCB, seu Estatuto Social e
                  Regulamentos Internos, Leis específicas que incidem em sua
                  atuação e em suas atividades associativas; existindo, ainda, o
                  tratamento de dados e informações para o cumprimento dos
                  contratos realizados com as parcerias e convênios para que o
                  associado e/ou beneficiário possa ter acesso às opções de
                  parcerias celebradas em favor da coletividade.
                </Text>
                <Text mb="8px" fontWeight="bold">
                  Como e por quanto tempo seus dados serão armazenados?
                </Text>
                <Text mb="8px">
                  A HEALTH ID cumpre com as normas legais relativas aos prazos
                  de retenção de dados pessoais, podendo mantê-los conforme
                  requerido por lei ou enquanto for necessário para execução de
                  contrato ou serviço. Para mantermos suas informações pessoais
                  seguras, usamos ferramentas físicas, eletrônicas e gerenciais
                  voltadas para a sua proteção e privacidade. Aplicamos essas
                  ferramentas levando em consideração a natureza dos dados
                  pessoais coletados, o contexto e a finalidade do tratamento,
                  além dos riscos que eventuais violações gerariam aos direitos
                  e liberdades do titular dos dados coletados e tratado
                </Text>
                <Text mb="8px" fontWeight="bold">
                  Direitos dos titulares de dados pessoais
                </Text>
                <Text mb="8px">
                  A Lei Geral de Proteção de Dados busca trazer ao titular dos
                  dados pessoais uma maior autonomia para gerir os seus dados.
                  Por isso, a legislação prevê em seu artigo 18 os direitos dos
                  titulares. A HEALTH ID assegura aos titulares o exercício dos
                  direitos previstos na LGPD, sendo alguns deles:
                </Text>
                <Text mb="8px">
                  Confirmar a existência de tratamento de dados, de maneira
                  simplificada ou em formato claro e completo.
                </Text>
                <Text mb="8px">
                  Acessar seus dados, podendo solicitá-los em uma cópia legível
                  sob forma impressa ou por meio eletrônico, seguro e idôneo.
                  Corrigir seus dados, ao solicitar a edição, correção ou
                  atualização destes.
                </Text>
                <Text mb="8px">
                  Limitar seus dados quando desnecessários, excessivos ou
                  tratados em desconformidade com a legislação através da
                  anonimização, bloqueio ou eliminação.
                </Text>
                <Text mb="8px">
                  Solicitar a portabilidade de seus dados, através de um
                  relatório de dados cadastrais que a HEALTH ID trata a seu
                  respeito. Eliminar seus dados tratados a partir de seu
                  consentimento, exceto nos casos previstos em lei.
                </Text>
                <Text mb="8px">
                  Revogar seu consentimento, desautorizando o tratamento de seus
                  dados.
                </Text>
                <Text mb="8px">
                  Informar-se sobre a possibilidade de não fornecer seu
                  consentimento e sobre as consequências da negativa.
                </Text>
                <Text mb="8px">
                  Para exercer os seus direitos, entre em contato com a HEALTH
                  ID através do e-mail: guilherme@clichealthid.com,
                  encaminhando-nos, conforme for o caso.
                </Text>
                <Text mb="8px" fontWeight="bold">
                  Utilização de Cookies
                </Text>
                <Text mb="8px">
                  Cookies são pequenos dados enviados por um servidor web para o
                  seu navegador e armazenados no seu computador ou dispositivo
                  pessoal. Para oferecer melhor experiência no uso de nossos
                  serviços web e aplicações, a HEALTH ID utiliza somente 1
                  cookies, gerados automaticamente pelo framework:
                </Text>
                <Text mb="8px">
                  (i) aviso sobre não preenchimento do questionário, sendo
                  diário e quando do primeiro acesso do associado (a).
                </Text>
                <Text mb="8px" fontWeight="bold">
                  Alterações na Política
                </Text>
                <Text mb="8px">
                  Se fizermos alterações na Política de Privacidade, iremos
                  informá-lo da mudança na próxima visita ao site e você também
                  poderá entrar em contato com a HEALTH ID via o e-mail
                  guilherme@clichealthid.com para direcionar seus
                  questionamentos em relação à mesma.
                </Text>
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            {preview ? (
              <Button onClick={onClose} colorScheme="purple">
                Fechar
              </Button>
            ) : (
              <Button
                onClick={onAccept}
                colorScheme="purple"
                isLoading={loading}
                isDisabled={loading}
              >
                Aceitar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AcceptTerms;
