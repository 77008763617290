import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { store } from "state";
import { ApolloQueryProvider } from "providers/apollo";

import App from "./App";
import theme from "theme/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <ApolloQueryProvider>
        <App />
      </ApolloQueryProvider>
    </Provider>
  </ChakraProvider>,
);
