import { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { AspectRatio, Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import "../markdown.css";

import PDFModal from "components/PDFModal";

export default ({ post }) => {
  const [templateFields, setTemplateFields] = useState({});
  const [isLargerThanBreakpoint] = useMediaQuery("(min-width: 765px)", {
    ssr: false,
  });
  const [download, setDownload] = useState(false);

  console.log('download', download);

  useEffect(() => {
    setTemplateFields(JSON.parse(post?.template_fields));
    setDownload(post?.imports?.find((i) => i?.type === "download")?.key);
  }, [post]);

  return (
    <Flex direction="column" h="100vh" marginX="5%" id="post">
      <AspectRatio mb="1.5em" maxW="1024px" ratio={16 / 9} mt={isLargerThanBreakpoint ? "90px" : "200px"}>
        <iframe width="100%" height="auto" src={post?.url_embed} title={post?.title} />
        {/* <iframe
          src={templateFields?.video_url}
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        /> */}
      </AspectRatio>

      <Text fontWeight="bold" fontSize="38px">
        {post?.title} {post?.subtitle && `- ${post?.subtitle}`}
      </Text>
      <Box>
        <ReactMarkdown
          children={templateFields?.body}
          remarkPlugins={[remarkGfm]}
        ></ReactMarkdown>

        {
          download &&
          <PDFModal
            title={post?.title}
            file={post?.imports?.find((i) => i?.type === "download")?.key}
          />
        }
      </Box>
    </Flex>
  );
};
